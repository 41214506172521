@import url("https: //fonts.googleapis.com/css2?family=La+Belle+Aurore&family=Poppins:wght@100;300;500;600&family=Roboto:wght@100;300;400;700;900&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #232333;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #636FFF;
} */

/* Handle on Hover */
/* ::-webkit-scrollbar-track:hover {
  background: #232333;
} */